export const categories = {
  Visioconférence: {
    title: "service.visio_conf.title",
    placeholder: "service.visio_conf.placeholder",
    icon: "Jitsi.svg",
    description: "service.visio_conf.description",
    wiki: "https://wiki.chatons.org/doku.php/services/visio-conference/jitsi",
    direct: true,
    exclude: ["EasyRTC", "Big Blue Button"],
  },
  "Rédaction collaborative": {
    title: "service.pad.title",
    placeholder: "service.pad.placeholder",
    icon: "Etherpad.svg",
    description: "service.pad.description",
    wiki: "https://wiki.chatons.org/doku.php/services/bureautique_en_ligne/etherpad",
    direct: true,
    exclude: ["Cryptpad", "Libreto"],
  },
  "Aide à la prise de rendez-vous": {
    title: "service.date.title",
    placeholder: "service.date.placeholder",
    icon: "Framadate.svg",
    description: "service.date.description",
    wiki: "https://wiki.chatons.org/doku.php/services/sondage/framadate",
    direct: false,
  },
  "Partage temporaire de fichiers": {
    title: "service.file_sharing.title",
    placeholder: "service.file_sharing.placeholder",
    icon: "Lufi.svg",
    description: "service.file_sharing.description",
    wiki: "https://wiki.chatons.org/doku.php/services/partage_temporaire_fichiers",
    direct: false,
  },
  "Tableau blanc collaboratif": {
    title: "service.postit.title",
    placeholder: "service.postit.placeholder",
    icon: "Scrumblr.svg",
    description: "service.postit.description",
    wiki: "https://wiki.chatons.org/doku.php/services/tableaux_blancs_collaboratifs/scrumblr",
    direct: true,
  },
  "Raccourcisseur d'URL": {
    title: "service.url_shortener.title",
    placeholder: "service.url_shortener.placeholder",
    icon: "Lstu.svg",
    description: "service.url_shortener.description",
    wiki: "https://wiki.chatons.org/doku.php/services/raccourcisseurs_de_liens",
    direct: false,
  },
  "Stockage / partage d'images": {
    title: "service.picture_sharing.title",
    placeholder: "service.picture_sharing.placeholder",
    icon: "Lutim.svg",
    description: "service.picture_sharing.description",
    wiki: "https://wiki.chatons.org/doku.php/services/partage_temporaire_images/lutim",
    direct: false,
  },
  "Tableur collaboratif": {
    title: "service.calc.title",
    placeholder: "service.calc.placeholder",
    icon: "Ethercalc.svg",
    description: "service.calc.description",
    wiki: "https://wiki.chatons.org/doku.php/services/bureautique_en_ligne/ethercalc",
    direct: true,
  },
  "Présentation collaborative": {
    title: "service.slides.title",
    placeholder: "service.slides.placeholder",
    icon: "chaton-surpris.png",
    description: "service.slides.description",
    wiki: "https://wiki.chatons.org/doku.php/services/bureautique_en_ligne/strut",
    direct: false,
  },
  "Transfert de messages chiffrés": {
    title: "service.bin.title",
    placeholder: "service.bin.placeholder",
    icon: "Pastebin.svg",
    description: "service.bin.description",
    wiki: "https://wiki.chatons.org/doku.php/services/partage_chiffre/privatebin",
    direct: false,
  },
}

export const categoriesOrder = [
  "Rédaction collaborative",
  "Visioconférence",
  "Aide à la prise de rendez-vous",
  "Tableur collaboratif",
  "Partage temporaire de fichiers",
  "Stockage / partage d'images",
  "Tableau blanc collaboratif",
  "Raccourcisseur d'URL",
  "Transfert de messages chiffrés",
]
